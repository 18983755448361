import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

axios.defaults.baseURL = 'https://app.vago.live/api/backend/'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
//axios.defaults.withCredentials = true;
//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

// https://devpress.csdn.net/vue/62f371bb7e668234661867fe.html


export default new Vuex.Store({
  state: {
    user: null
  },

  mutations: {

    setUserData (state, userData) {
      state.user = userData
      localStorage.setItem('user', JSON.stringify(userData))
      axios.defaults.headers.common.Authorization = `Bearer ${userData.access_token}`
    },

    clearUserData () {
      if ( ! confirm('¿Deseas cerrar la sesión?')) {
        return;
      }
      localStorage.removeItem('user')
      location.reload()
    }
  },

  actions: {
    login ({ commit }, credentials) {
      return axios
        .post('login', credentials)
        .then(({ data }) => {
          commit('setUserData', data)
        })
    },

    logout ({ commit }) {
      commit('clearUserData')
    }
  },

  getters : {
    isLogged: state => !! state.user
  }
})
