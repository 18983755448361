<template>
  <div id="app">
    <!--<nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav>
    -->
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <div class="container-fluid justify-content-between">

          <a class="navbar-brand" href="#">
            <img src="./assets/logo.png" alt="Logo" width="24" height="24" class="d-inline-block align-text-top">
            Backend
          </a>

          <button class="btn btn-danger" type="button" @click="logout()" v-if="isLogged">
            <i class="fas fa-arrow-right-from-bracket"></i> Cerrar sesión
          </button>

      
      </div>
    </nav>

    <!--router-link to="/" v-if="isLogged">Home</router-link-->
    <!--router-link to="/login" v-else>Login</router-link-->
    
      
    <router-view/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'



export default {
  computed: {
    ...mapGetters([
      'isLogged'
    ])
  },

  methods: {
    logout () {
      this.$store.dispatch('logout')
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
