import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'


Vue.config.productionTip = false

import './assets/sass/app.scss'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

import axios from 'axios'

axios.defaults.baseURL = 'https://app.vago.live/api/backend/'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';


Vue.component('form-phone-input', require('./components/PhoneNumber.vue').default);


new Vue({
  router,
  store,
  
  created () {
    const userInfo = localStorage.getItem('user')
    
    if (userInfo) {
      const userData = JSON.parse(userInfo)
      this.$store.commit('setUserData', userData)
    }
    
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          this.$store.dispatch('logout')
        }
        return Promise.reject(error)
      }
    )
  },
  render: function (h) { return h(App) },
}).$mount('#app')
