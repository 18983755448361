<template>
  <div class="container py-4">

    <div class="row">
      

      <div class="col-12">

        <div v-if="isOffline" class="alert alert-warning" role="alert">
          <i class="fas fa-triangle-exclamation"></i> Sin conexión a Internet
        </div>
        <div v-else class="alert alert-light" role="alert">
          <i class="fas fa-wifi text-success"></i> Conectado a Internet
        </div>

        <div class="d-flex justify-content-between mt-5">
          <h1 class="h3 text-muted">
            Sucursales registradas
          </h1>
          <div class="mb-3">
            <button :disabled="isSyncingCatalogs || isOffline" @click="syncCatalogs" class="btn btn-dark me-4" type="button" >
              <i :class="{ 'fas fa-spin fa-rotate': isSyncingCatalogs, 'fas fa-rotate': ! isSyncingCatalogs }"></i> Sincronizar catálogos
            </button>

            <button class="btn btn-success" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
              <i class="fas fa-plus-circle"></i> Agregar
            </button>
          </div>
        </div>

        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col" class="text-muted text-start">Sucursal</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody v-if="branches.length > 0" >
            <tr v-for="(branch, branchIndex) in branches" :key="branchIndex">
              <th scope="row" class="text-start">
                <strong v-if="branch.add_company">
                  {{ branch.companyName }}
                </strong>
                <strong v-else>
                  {{ branch.companyLabel }}
                </strong>
                - 
                <span class="text-muted">
                  {{ branch.name }}
                </span>
              </th>
              <td class="text-end">
                <button @click="_syncBranch(branch, branchIndex)" :disabled="branch.synced || isOffline" class="btn btn-warning me-3" type="button">
                  <i class="fas" :class="{ 'fa-rotate fa-spin': branch.syncing, 'fa-rotate': ! branch.syncing }"></i>
                </button>

                <button :disabled="branch.syncing" data-bs-toggle="modal" data-bs-target="#editModal" @click="editBranch(branch, branchIndex)" class="btn btn-primary me-3" type="button">
                  <i class="fas fa-edit"></i>
                </button>

                <button :disabled="branch.syncing" @click="deleteBranch(branch, branchIndex)" class="btn btn-danger" type="button">
                  <i class="fas fa-trash"></i>
                </button>
              </td>
            </tr>
            
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="3" class="text-center text-muted">
                No hay sucursales registradas
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>

    <!-- Modal -->
    <div class="modal fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">
              Agregar sucursal
            </h1>
            <button @click="closeModal" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Cerrar"></button>
          </div>
          <div class="modal-body text-start">
            
            <div class="form-row">
              <div class="col-12 col-md-6">
                <div class=" mb-3">
                  <label for="contact">Contacto</label>
                  <textarea v-model="branch.notes" rows="3" class="form-control" id="contact" ></textarea>
                  
                </div>
              </div>
            </div>

            <h3 class="h5 text-start text-muted">Empresa</h3>

            <div class="bg-light p-2 mb-3">
              <div class="form-row mb-3">
                <div class="col-12 col-md-6">


                  <div v-if=" ! branch.add_company" class="col-12 mb-3">
                    <div class="">
                      <label for="categories">Empresas</label>

                      <v-select v-model="branch.company" :reduce="company => company.id" label="name" :options="companies"></v-select>
                    </div>
                  </div>

                  <div class="form-check form-switch mt-2 d-flex justify-content-start">
                    <input v-model="branch.add_company" class="form-check-input" type="checkbox" role="switch" id="add_company">
                    <label class="form-check-label ms-2" for="add_company">Agregar Empresa</label>
                  </div>
                </div>
              </div>

              <div v-if="branch.add_company" class="form-row mb-3">
                <div class="col-12 col-md-6">
                  <div class="form-floating">
                    <input type="text" v-model="branch.companyName" class="form-control" id="companyName" >
                    <label for="companyName">Empresa</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-row mb-3">
              <div class="col-12 col-md-6">
                <div class="form-floating">
                  <input type="text" v-model="branch.name" class="form-control" id="name" >
                  <label for="name">Sucursal</label>
                </div>
              </div>
            </div>

            <div class="form-row mb-3">
              <div class="col-12 col-md-12">
                <div class="form-floating">
                  <input type="text" v-model="branch.menu_digital" class="form-control" id="menu_digital" >
                  <label for="menu_digital">Menú Digital</label>
                </div>
              </div>
            </div>

            <h3 class="h5 text-start text-muted">Dirección</h3>

            <div class="form-row mb-3">
              <div class="col-12 col-md-12">
                <div class="form-floating">
                  <input type="text" v-model="branch.street" class="form-control" id="street" >
                  <label for="street">Calle</label>
                </div>
              </div>

             
            </div>

            <div class="row mb-3">

              <div class="col-12 col-md-6">
                <div class="form-floating">
                  <input type="text" v-model="branch.ext_number" class="form-control" id="ext_number" >
                  <label for="ext_number">No. Exterior</label>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-floating">
                  <input type="text" v-model="branch.int_number" class="form-control" id="int_number" >
                  <label for="int_number">No. Interior</label>
                </div>
              </div>
            </div>

            <div class="row mb-3">

              <div class="col-12 col-md-6">
                <div class="form-floating">
                  <input type="text" v-model="branch.neighborhood" class="form-control" id="neighborhood" >
                  <label for="neighborhood">Colonia</label>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-floating">
                  <input type="text" v-model="branch.zipcode" class="form-control" id="zipcode" >
                  <label for="zipcode">Código Postal</label>
                </div>
              </div>
            </div>

            <div class="row mb-3">

              <div class="col-12 col-md-6">
                <div class="form-floating">
                  <input type="text" v-model="branch.city" class="form-control" id="city" >
                  <label for="city">Ciudad</label>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-floating">
                  <input type="text" v-model="branch.state" class="form-control" id="state" >
                  <label for="state">Estado</label>
                </div>
              </div>
            </div>

            <div class="row mb-3">

              <div class="col-12 col-md-6">
                <div class="form-floating">
                  <input type="text" v-model="branch.latitude" class="form-control" id="latitude" >
                  <label for="latitude">Latitud</label>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-floating">
                  <input type="text" v-model="branch.longitude" class="form-control" id="longitude" >
                  <label for="longitude">Longitud</label>
                </div>
              </div>
            </div>

            <div class="row mb-3 bg-light">

              <div class="col-12 mb-3">
                <div class="">
                  <label for="categories">Categorías</label>

                  <v-select taggable v-model="branch.categories" multiple :reduce="category => category.id" label="name" :options="categories"></v-select>
                </div>
              </div>

              <div class="col-12 mb-3">
                <div class="form-floating">
                  <input type="text" v-model="branch.custom_category" class="form-control" id="custom_category" >
                  <label for="custom_category">Categoría a capturar</label>
                </div>
              </div>

            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label for="phone"><i class="fas fa-phone"></i> Teléfono</label>
                <form-phone-input ref="phone" :field-value="branch.phone" v-on:phone-updated="onPhoneUpdated" field-name="phone" default-country="MX">
                    <!--div slot="errors" class="text-danger">
                        <span class="d-block text-danger" v-if="formModal.errors.has('phone.formatted')">
                            {{ formModal.errors.get('phone.formatted') }}
                        </span>
                    </div-->
                </form-phone-input>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label for="whatsapp"><i class="fab fa-whatsapp"></i> Whatsapp</label>
                <form-phone-input ref="whatsapp" :field-value="branch.whatsapp" v-on:phone-updated="onWhatsappUpdated" field-name="whatsapp" default-country="MX">
                    <!--div slot="errors" class="text-danger">
                        <span class="d-block text-danger" v-if="formModal.errors.has('phone.formatted')">
                            {{ formModal.errors.get('phone.formatted') }}
                        </span>
                    </div-->
                </form-phone-input>
              </div>
            </div>

            <h3 class="h5 text-start text-muted">Wifi</h3>

            <div class="row mb-3 bg-light p-3">

              <div class="col-12 col-md-6 mb-3">
                <div class="form-floating">
                  <input type="text" v-model="branch.wifi_ssid" class="form-control" id="wifi_ssid" >
                  <label for="custom_catewifi_ssidgory">SSID</label>
                </div>
              </div>

              <div class="col-12 col-md-6 mb-3">
                <div class="form-floating">
                  <input type="text" v-model="branch.wifi_password" class="form-control" id="wifi_password" >
                  <label for="wifi_password">Password</label>
                </div>
              </div>

              <div class="col-12">
                <div class="form-check form-switch mt-2 d-flex justify-content-start">
                  <input v-model="branch.wifi_is_wep" class="form-check-input" type="checkbox" role="switch" id="wifi_is_wep">
                  <label class="form-check-label ms-2" for="wifi_is_wep">Cifrado WEP</label>
                </div>
              </div>

            </div>

            <h3 class="h5 text-start text-muted">Horarios</h3>

            <div class="p-3 bg-light mb-4">
              <table class="table table-sm mb-4">
                <thead>
                  <tr>
                    <th scope="col" class="text-muted text-start">Día</th>
                    <th scope="col" class="text-muted text-start">Abre</th>
                    <th scope="col" class="text-muted text-start">Cierra</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody v-if="branch.schedules.length > 0">
                  <tr v-for="(schedule, scheduleIndex) in branch.schedules" :key="scheduleIndex">
                    <td>
                      {{ schedule.dayLabel }}
                    </td>
                    <td>
                      {{ schedule.open_time }}
                    </td>
                    <td>
                      {{ schedule.close_time }}
                    </td>
                    <td class="text-end">
                      <button class="btn btn-danger" type="button" @click="deleteSchedule(scheduleIndex)">
                        <i class="fas fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="4" class="text-center text-muted">
                      No hay horarios registrados
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="row mb-3  align-items-end">

                <div class="col-12 col-md-3 mb-3">
                    <label for="dayLabel">Día</label>
                    <select v-model="newSchedule.day" class="form-select" id="dayLabel" >
                      <option value="monday">Lunes</option>
                      <option value="tuesday">Martes</option>
                      <option value="wednesday">Miércoles</option>
                      <option value="thursday">Jueves</option>
                      <option value="friday">Viernes</option>
                      <option value="saturday">Sábado</option>
                      <option value="sunday">Domingo</option>
                    </select>
                    
                </div>

                <div class="col-12 col-md-3 mb-3">
                  <label>Abre a la(s)</label>
                  <date-picker
                      :append-to-body="false"
                      lang="es"
                      v-model="newSchedule.open_time"
                      format="HH:mm"
                      valueType="format"
                      type="time"
                  ></date-picker>
                </div>

                <div class="col-12 col-md-3 mb-3">
                  <label>Cierra a la(s)</label>
                  <date-picker
                      :append-to-body="false"
                      lang="es"
                      v-model="newSchedule.close_time"
                      format="HH:mm"
                      valueType="format"
                      type="time"
                  ></date-picker>
                </div>

                <div class="col-12 col-md-3 mb-3">
                  <button class="btn btn-dark" type="button" @click="addSchedule">
                    <i class="fas fa-plus-circle"></i> Agregar
                  </button>
                </div>

              </div>
            </div>

            <div class="row mb-3 bg-light">

              <div class="col-12 mb-3">

                <label for="filters">Filtros</label>

                <div class="row mt-4">
                  

                  <div v-for="(filter, filterIndex) in filters" :key="filterIndex" class="col-12 col-md-4">
                    <h6 class="text-muted">{{ filter.name }}</h6>
                    <ul class="list-unstyled">
                      <li v-for="(value, filterValueIndex) in filter.values" :key="filterValueIndex">
                        <div class="form-check form-switch mt-2 d-flex justify-content-start">
                          <input v-model="value.selected" :value="value.id" class="form-check-input" type="checkbox" role="switch" :id="`filter_value_${filterIndex}_${filterValueIndex}`">
                          <label class="form-check-label ms-2" :for="`filter_value_${filterIndex}_${filterValueIndex}`">{{ value.value }}</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col-12 mb-3">
                <div class="form-floating">
                  <input type="text" v-model="branch.custom_filters" class="form-control" id="custom_filters" >
                  <label for="custom_filters">Filtro a capturar</label>
                </div>
              </div>

            </div>
      
          </div>
          <div class="modal-footer">
            <button @click="closeModal()" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
            <button @click="saveBranch" type="button" class="btn btn-success">Guardar</button>
          </div>
        </div>
      </div>
    </div>

     <!-- Modal -->
     <div class="modal fade" id="editModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="editModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="editModalLabel">
              Editar sucursal
            </h1>
            <button @click="closeModal" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Cerrar"></button>
          </div>
          <div class="modal-body text-start">
            
            <div class="form-row">
              <div class="col-12 col-md-6">
                <div class=" mb-3">
                  <label for="contact">Contacto</label>
                  <textarea v-model="branch.notes" rows="3" class="form-control" id="contact" ></textarea>
                  
                </div>
              </div>
            </div>

            <h3 class="h5 text-start text-muted">Empresa</h3>

            <div class="bg-light p-2 mb-3">
              <div class="form-row mb-3">
                <div class="col-12 col-md-6">


                  <div v-if=" ! branch.add_company" class="col-12 mb-3">
                    <div class="">
                      <label for="categories">Empresas</label>

                      <v-select v-model="branch.company" :reduce="company => company.id" label="name" :options="companies"></v-select>
                    </div>
                  </div>

                  <div class="form-check form-switch mt-2 d-flex justify-content-start">
                    <input v-model="branch.add_company" class="form-check-input" type="checkbox" role="switch" id="add_company">
                    <label class="form-check-label ms-2" for="add_company">Agregar Empresa</label>
                  </div>
                </div>
              </div>

              <div v-if="branch.add_company" class="form-row mb-3">
                <div class="col-12 col-md-6">
                  <div class="form-floating">
                    <input type="text" v-model="branch.companyName" class="form-control" id="companyName" >
                    <label for="companyName">Empresa</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-row mb-3">
              <div class="col-12 col-md-6">
                <div class="form-floating">
                  <input type="text" v-model="branch.name" class="form-control" id="name" >
                  <label for="name">Sucursal</label>
                </div>
              </div>
            </div>

            <div class="form-row mb-3">
              <div class="col-12 col-md-12">
                <div class="form-floating">
                  <input type="text" v-model="branch.menu_digital" class="form-control" id="menu_digital" >
                  <label for="menu_digital">Menú Digital</label>
                </div>
              </div>
            </div>

            <h3 class="h5 text-start text-muted">Dirección</h3>

            <div class="form-row mb-3">
              <div class="col-12 col-md-12">
                <div class="form-floating">
                  <input type="text" v-model="branch.street" class="form-control" id="street" >
                  <label for="street">Calle</label>
                </div>
              </div>

             
            </div>

            <div class="row mb-3">

              <div class="col-12 col-md-6">
                <div class="form-floating">
                  <input type="text" v-model="branch.ext_number" class="form-control" id="ext_number" >
                  <label for="ext_number">No. Exterior</label>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-floating">
                  <input type="text" v-model="branch.int_number" class="form-control" id="int_number" >
                  <label for="int_number">No. Interior</label>
                </div>
              </div>
            </div>

            <div class="row mb-3">

              <div class="col-12 col-md-6">
                <div class="form-floating">
                  <input type="text" v-model="branch.neighborhood" class="form-control" id="neighborhood" >
                  <label for="neighborhood">Colonia</label>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-floating">
                  <input type="text" v-model="branch.zipcode" class="form-control" id="zipcode" >
                  <label for="zipcode">Código Postal</label>
                </div>
              </div>
            </div>

            <div class="row mb-3">

              <div class="col-12 col-md-6">
                <div class="form-floating">
                  <input type="text" v-model="branch.city" class="form-control" id="city" >
                  <label for="city">Ciudad</label>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-floating">
                  <input type="text" v-model="branch.state" class="form-control" id="state" >
                  <label for="state">Estado</label>
                </div>
              </div>
            </div>

            <div class="row mb-3">

              <div class="col-12 col-md-6">
                <div class="form-floating">
                  <input type="text" v-model="branch.latitude" class="form-control" id="latitude" >
                  <label for="latitude">Latitud</label>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-floating">
                  <input type="text" v-model="branch.longitude" class="form-control" id="longitude" >
                  <label for="longitude">Longitud</label>
                </div>
              </div>
            </div>

            <div class="row mb-3 bg-light">

              <div class="col-12 mb-3">
                <div class="">
                  <label for="categories">Categorías</label>

                  <v-select taggable v-model="branch.categories" multiple :reduce="category => category.id" label="name" :options="categories"></v-select>
                </div>
              </div>

              <div class="col-12 mb-3">
                <div class="form-floating">
                  <input type="text" v-model="branch.custom_category" class="form-control" id="custom_category" >
                  <label for="custom_category">Categoría a capturar</label>
                </div>
              </div>

            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label for="phone"><i class="fas fa-phone"></i> Teléfono</label>
                <form-phone-input ref="phone" :field-value="branch.phone" v-on:phone-updated="onPhoneUpdated" field-name="phone" default-country="MX">
                    <!--div slot="errors" class="text-danger">
                        <span class="d-block text-danger" v-if="formModal.errors.has('phone.formatted')">
                            {{ formModal.errors.get('phone.formatted') }}
                        </span>
                    </div-->
                </form-phone-input>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label for="whatsapp"><i class="fab fa-whatsapp"></i> Whatsapp</label>
                <form-phone-input ref="whatsapp" :field-value="branch.whatsapp" v-on:phone-updated="onWhatsappUpdated" field-name="whatsapp" default-country="MX">
                    <!--div slot="errors" class="text-danger">
                        <span class="d-block text-danger" v-if="formModal.errors.has('phone.formatted')">
                            {{ formModal.errors.get('phone.formatted') }}
                        </span>
                    </div-->
                </form-phone-input>
              </div>
            </div>

            <h3 class="h5 text-start text-muted">Wifi</h3>

            <div class="row mb-3 bg-light p-3">

              <div class="col-12 col-md-6 mb-3">
                <div class="form-floating">
                  <input type="text" v-model="branch.wifi_ssid" class="form-control" id="wifi_ssid" >
                  <label for="custom_catewifi_ssidgory">SSID</label>
                </div>
              </div>

              <div class="col-12 col-md-6 mb-3">
                <div class="form-floating">
                  <input type="text" v-model="branch.wifi_password" class="form-control" id="wifi_password" >
                  <label for="wifi_password">Password</label>
                </div>
              </div>

              <div class="col-12">
                <div class="form-check form-switch mt-2 d-flex justify-content-start">
                  <input v-model="branch.wifi_is_wep" class="form-check-input" type="checkbox" role="switch" id="wifi_is_wep">
                  <label class="form-check-label ms-2" for="wifi_is_wep">Cifrado WEP</label>
                </div>
              </div>

            </div>

            <h3 class="h5 text-start text-muted">Horarios</h3>

            <div class="p-3 bg-light mb-4">
              <table class="table table-sm mb-4">
                <thead>
                  <tr>
                    <th scope="col" class="text-muted text-start">Día</th>
                    <th scope="col" class="text-muted text-start">Abre</th>
                    <th scope="col" class="text-muted text-start">Cierra</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody v-if="branch.schedules.length > 0">
                  <tr v-for="(schedule, scheduleIndex) in branch.schedules" :key="scheduleIndex">
                    <td>
                      {{ schedule.dayLabel }}
                    </td>
                    <td>
                      {{ schedule.open_time }}
                    </td>
                    <td>
                      {{ schedule.close_time }}
                    </td>
                    <td class="text-end">
                      <button class="btn btn-danger" type="button" @click="deleteSchedule(scheduleIndex)">
                        <i class="fas fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="4" class="text-center text-muted">
                      No hay horarios registrados
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="row mb-3  align-items-end">

                <div class="col-12 col-md-3 mb-3">
                    <label for="dayLabel">Día</label>
                    <select v-model="newSchedule.day" class="form-select" id="dayLabel" >
                      <option value="monday">Lunes</option>
                      <option value="tuesday">Martes</option>
                      <option value="wednesday">Miércoles</option>
                      <option value="thursday">Jueves</option>
                      <option value="friday">Viernes</option>
                      <option value="saturday">Sábado</option>
                      <option value="sunday">Domingo</option>
                    </select>
                    
                </div>

                <div class="col-12 col-md-3 mb-3">
                  <label>Abre a la(s)</label>
                  <date-picker
                      :append-to-body="false"
                      lang="es"
                      v-model="newSchedule.open_time"
                      format="HH:mm"
                      valueType="format"
                      type="time"
                  ></date-picker>
                </div>

                <div class="col-12 col-md-3 mb-3">
                  <label>Cierra a la(s)</label>
                  <date-picker
                      :append-to-body="false"
                      lang="es"
                      v-model="newSchedule.close_time"
                      format="HH:mm"
                      valueType="format"
                      type="time"
                  ></date-picker>
                </div>

                <div class="col-12 col-md-3 mb-3">
                  <button class="btn btn-dark" type="button" @click="addSchedule">
                    <i class="fas fa-plus-circle"></i> Agregar
                  </button>
                </div>

              </div>
            </div>

            <div class="row mb-3 bg-light">

              <div class="col-12 mb-3">

                <label for="filters">Filtros</label>

                <div class="row mt-4">
                  

                  <div v-for="(filter, filterIndex) in filters" :key="filterIndex" class="col-12 col-md-4">
                    <h6 class="text-muted">{{ filter.name }}</h6>
                    <ul class="list-unstyled">
                      <li v-for="(value, filterValueIndex) in filter.values" :key="filterValueIndex">
                        <div class="form-check form-switch mt-2 d-flex justify-content-start">
                          <input v-model="value.selected" :value="value.id" class="form-check-input" type="checkbox" role="switch" :id="`filter_value_${filterIndex}_${filterValueIndex}`">
                          <label class="form-check-label ms-2" :for="`filter_value_${filterIndex}_${filterValueIndex}`">{{ value.value }}</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col-12 mb-3">
                <div class="form-floating">
                  <input type="text" v-model="branch.custom_filters" class="form-control" id="custom_filters" >
                  <label for="custom_filters">Filtro a capturar</label>
                </div>
              </div>

            </div>
      
          </div>
          <div class="modal-footer">
            <button @click="closeModal()" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
            <button @click="updateBranch()" type="button" class="btn btn-success" data-bs-dismiss="modal"><i class="fas fa-rotate"></i> Actualizar</button>
          </div>
        </div>
      </div>
    </div>
    
    <!--HelloWorld /-->
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import axios from 'axios';
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Swal from 'sweetalert2';

export default {

  data: () => ({
    branches: [],

    categories: [],
    filters: [],
    companies: [],

    newSchedule: {
      day: '',
      dayLabel: '',
      open_time: '',
      close_time: ''
    },


    isEditing: false,
    currentIndex: null,

    branch: {
      id: null,
      notes: '',
      company: null,
      companyName: '',
      companyLabel: '',
      add_company: false,
      name: '',
      menu_digital: '',
      street: '',
      ext_number: '',
      int_number: '',
      neighborhood: '',
      zipcode: '',
      city: '',
      state: 'Jalisco',
      latitude: '',
      longitude: '',
      categories: [],
      custom_category: '',
      custom_filters: '',
      phone: {
          country_code: null,
          phone: null,
          prefix: null,
          formatted: null,
      },
      whatsapp: {
          country_code: null,
          phone: null,
          prefix: null,
          formatted: null,
      },
      wifi_ssid: '',
      wifi_password: '',
      wifi_is_wep: false,
      schedules: [],
      filters: [],
      filters_ids: [],
      syncing: false,
      synced: false,
    },
    database: null,
    isOffline: ! navigator.onLine,
    isSyncingCatalogs: false,
  }),
  components: {
    //HelloWorld
    vSelect,
    DatePicker
  },
  async created() {
    this.database = await this.getDatabase()

    await this.syncStoredCatalogs()

    await this.getBranches()
    
  },
  mounted() {
    window.addEventListener('online',  () => {

      console.log('online event')

      this.isOffline = false
    })

    window.addEventListener('offline',  () => {

      console.log('offline event')
      this.isOffline = true
    })

    console.log('mounted', 'isOffline', this.isOffline);
  },
  methods: {

    editBranch (branch, branchIndex) {
      this.branch = branch;

      this.isEditing = true;
      this.currentIndex = branchIndex;

      this.branch.companyLabel = this.companies.find((company) => {
        return company.id == this.branch.company
      }).name

      this.branch.filters = this.filters;

      this.branch.filters.forEach((filter) => {
        filter.values.forEach((value) => {
          if (this.branch.filters_ids.includes(value.id)) {
            value.selected = true
          }
        })
      })
      

      console.log('this.branch', this.branch)

      this.$refs.phone.setValue(this.branch.phone)
      this.$refs.whatsapp.setValue(this.branch.whatsapp)
    },

    async updateBranch () {

      return new Promise((resolve, reject) => {
        let transaction  = this.database.transaction('branches', 'readwrite');

        transaction.oncomplete = (event) => {
          console.log('transaction complete', event)
          resolve(event.target.result)
        };

        transaction.onerror = (event) => {
          console.log('transaction error', event)
          reject('Error updating the database')
        };

        if (this.branch.add_company && this.branch.companyName.trim().length == 0 ) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'El campo Sucursal es requerido.',
          })

          return;
        }

        if ( ! this.branch.add_company && this.branch.company == null ) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'El campo Sucursal es requerido.',
          })

          return;
        }

        if ( this.branch.add_company) {
          this.branch.company = null;
        } else {
          /*this.branch.companyName = this.companies.find((company) => {
            return company.id == this.branch.company
          }).name*/
          this.branch.companyName = '';

          this.branch.companyLabel = this.companies.find((company) => {
            return company.id == this.branch.company
          }).name
        }

        this.branch.filters_ids = [];

        this.filters.forEach((filter) => {
          filter.values.forEach((value) => {
            if (value.selected) {
              this.branch.filters_ids.push(value.id)
            }
          })
        })

        this.filters.forEach((filter) => {
          filter.values.forEach((value) => {
            value.selected = false
          })
        })

        let request = transaction.objectStore('branches').put(this.branch)

        request.onsuccess = (event) => {
          console.log('success updating the branch', event)

          this.$set(this.branches, this.currentIndex, this.branch);

          this.closeModal();

          this.currentIndex = null;

          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            }
          });

          Toast.fire({
            icon: "success",
            title: "Actualizado correctamente."
          });

          resolve(event.target.result)
        };

        request.onerror = (event) => {
          console.log('request error', event)

          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            }
          });

          Toast.fire({
            icon: "error",
            title: "Error al actualizar."
          });

          reject('Error saving the branch')
        };
      })

    },

    deleteBranch(branch, branchIndex) {
      Swal.fire({
        title: 'Eliminar Sucursal',
        text: "¿Deseas eliminar el registro de la sucursal?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#6c757d',
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {

        if (result.isConfirmed) {
          this.database.transaction('branches', 'readwrite').objectStore('branches').delete(branch.id)

          this.branches.splice(branchIndex, 1);

          Swal.fire(
            'Eliminado',
            'El registro ha sido eliminado.',
            'success'
          )
        }
      })
    },

    closeModal() {
      this.branch = {
        id: null,
        notes: '',
        company: null,
        companyName: '',
        companyLabel: '',
        add_company: false,
        name: '',
        menu_digital: '',
        street: '',
        ext_number: '',
        int_number: '',
        neighborhood: '',
        zipcode: '',
        city: '',
        state: 'Jalisco',
        latitude: '',
        longitude: '',
        categories: [],
        custom_category: '',
        custom_filters: '',
        phone: {
            country_code: null,
            phone: null,
            prefix: null,
            formatted: null,
        },
        whatsapp: {
            country_code: null,
            phone: null,
            prefix: null,
            formatted: null,
        },
        wifi_ssid: '',
        wifi_password: '',
        wifi_is_wep: false,
        schedules: [],
        filters: [],
        filters_ids: [],
        syncing: false,
        synced: false,
      }
    },

    deleteSchedule(scheduleIndex) {
      this.branch.schedules.splice(scheduleIndex, 1);
    },

    addSchedule () {

      let dayLabel = '';

      switch(this.newSchedule.day) {
        case 'monday':
          dayLabel = 'Lunes';
          break;
        case 'tuesday':
          dayLabel = 'Martes';
          break;
        case 'wednesday':
          dayLabel = 'Miércoles';
          break;
        case 'thursday':
          dayLabel = 'Jueves';
          break;
        case 'friday':
          dayLabel = 'Viernes';
          break;
        case 'saturday':
          dayLabel = 'Sábado';
          break;
        case 'sunday':
          dayLabel = 'Domingo';
          break;
      }

      this.branch.schedules.push({
        day: this.newSchedule.day,
        dayLabel: dayLabel,
        open_time: this.newSchedule.open_time,
        close_time: this.newSchedule.close_time,
      })
    },

    onPhoneUpdated(dataPhone) {
      this.branch.phone.country_code = dataPhone.country_code;
      this.branch.phone.phone = dataPhone.phone;
      this.branch.phone.formatted = dataPhone.formatted;
      this.branch.phone.prefix = dataPhone.prefix;
    },

    onWhatsappUpdated(dataPhone) {
      this.branch.whatsapp.country_code = dataPhone.country_code;
      this.branch.whatsapp.phone = dataPhone.phone;
      this.branch.whatsapp.formatted = dataPhone.formatted;
      this.branch.whatsapp.prefix = dataPhone.prefix;
    },

    // https://chariotsolutions.com/blog/post/client-side-data-persistence-with-indexeddb/

    async getDatabase() {

      return new Promise((resolve, reject) => {
        let db = window.indexedDB.open('branches', 1)

        db.onerror = (event) => {
          console.log('error', event)
          reject('Error opening the database')
        };

        db.onsuccess = (event) => {
          console.log('success', event)
          resolve(event.target.result)
        };

        db.onupgradeneeded = (event) => {
          console.log('upgradeneeded', event)

          const db = event.target.result;
          console.log('db', db);

          if (event.oldVersion < 2) {
            db.createObjectStore('branches', { keyPath: 'id' })
            db.createObjectStore('categories', { keyPath: 'id' })
            db.createObjectStore('companies', { keyPath: 'id' })
            db.createObjectStore('filters', { keyPath: 'id' })
          }

          if (event.oldVersion < 3) {
            db.deleteObjectStore('branches')
            db.deleteObjectStore('categories')
            db.deleteObjectStore('companies')
            db.deleteObjectStore('filters')

            db.createObjectStore('branches', { keyPath: 'id' })
            db.createObjectStore('categories', { keyPath: 'id' })
            db.createObjectStore('companies', { keyPath: 'id' })
            db.createObjectStore('filters', { keyPath: 'id' })
          }
          
        };
        
      })
      
    },

    async _syncBranch(item, index) {

            if (item.syncing) {
                return;
            }
    
            item.syncing = true;
    
            axios.post('branches/sync', item)
                .then( (response) => {
                    return response.data.data;
                })
                .then( async (data) => {
                    console.log('=================');
                    console.log('data branch ', data);
                    console.log('=================');

                    item.synced = true;
                    item.syncing = false;

                    this.branches.splice(index, 1);
                    
                    this.database.transaction('branches', 'readwrite').objectStore('branches').delete(item.id)

                    const Toast = Swal.mixin({
                      toast: true,
                      position: "top-end",
                      showConfirmButton: false,
                      timer: 3000,
                      timerProgressBar: true,
                      didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                      }
                    });

                    Toast.fire({
                      icon: "success",
                      title: "Sucursal sincronizada."
                    });
                })
                .catch( async (error) => {
                    console.log('error', error);

                    item.syncing = false;
    
                    if (error.response) {
                
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        //console.log(error.response.headers);
                        console.log('error.response.status', error.response.status);
                        console.log('error.response.data', error.response.data);
                
                        switch(error.response.status) {
                        case 422:
                            let errors = error.response.data.errors;
                            /*this.setState({
                            errorPasswordOld: ((errors.current_password && errors.current_password.length) ? errors.current_password[0] : ''),
                            errorPasswordNew: ((errors.new_password && errors.new_password.length) ? errors.new_password[0] : ''),
                            errorPasswordConfirmation: ((errors.new_password_confirmation && errors.new_password_confirmation.length) ? errors.new_password_confirmation[0] : ''),
                            });*/
                            break;
                        default:
                            alert('Ocurrió un error');
                            break;
                        }
                    }
                })
    },

    async syncStoredCatalogs() {
      return new Promise((resolve, reject) => {
        let companiesRequest = this.database.transaction('companies').objectStore('companies').getAll()

        let successCounter = 0;

        companiesRequest.onsuccess = () => {
            let companies = companiesRequest.result;
            //console.log('companies', companies); // Array of users with the name 'Lazlo'

            this.companies = companies;

            successCounter++;
        };

        companiesRequest.onerror = (event) => {
          console.log('companiesRequest error', event)
          reject('Error getting the companies')
        };

        /////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////

        let categoriesRequest = this.database.transaction('categories').objectStore('categories').getAll()

        categoriesRequest.onsuccess = () => {
            let categories = categoriesRequest.result;
            //console.log('categories', categories); // Array of users with the name 'Lazlo'

            this.categories = categories;

            successCounter++;
        };

        categoriesRequest.onerror = (event) => {
          console.log('categoriesRequest error', event)
          reject('Error getting the categories')
        };

        /////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////

        let filtersRequest = this.database.transaction('filters').objectStore('filters').getAll()

        filtersRequest.onsuccess = () => {
            let filters = filtersRequest.result;
            //console.log('filters', filters); // Array of users with the name 'Lazlo'

            this.filters = filters;

            successCounter++;
        };

        filtersRequest.onerror = (event) => {
          console.log('filtersRequest error', event)
          reject('Error getting the filters')
        };

        resolve({
          filters: this.filters,
          categories: this.categories,
          companies: this.companies,
        })

      })
    },

    async getBranches () {

      return new Promise((resolve, reject) => {

        let branchesRequest = this.database.transaction('branches').objectStore('branches').getAll()


        branchesRequest.onsuccess = () => {
            let branches = branchesRequest.result;
            console.log('branches getall', branches); // Array of users with the name 'Lazlo'

            this.branches = branches;

            resolve(branches)
        };

        branchesRequest.onerror = (event) => {
          console.log('branchesRequest error', event)
          reject('Error getting the branches')
        };

      })
    },

    async storeCatalogs() {
      return new Promise((resolve, reject) => {
        
        let successSyncCount = 0;

        //////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////
        
        let transaction = this.database.transaction('categories', 'readwrite')

        let categoriesStore = transaction.objectStore('categories')

        categoriesStore.clear();

        this.categories.forEach((item) => {
          const request = categoriesStore.add(item);

          request.onsuccess = (event) => {
            // event.target.result === customer.ssn;
            successSyncCount++;
          };

          request.onerror = event => {
            console.log('error sincronizar las categorías', event)
            reject('Error al sincronizar las categorías');
          }
        });

        //////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////

        transaction = this.database.transaction('companies', 'readwrite')

        let companiesStore = transaction.objectStore('companies')

        companiesStore.clear();

        this.companies.forEach((item) => {
          const request = companiesStore.add(item);

          request.onsuccess = (event) => {
            // event.target.result === customer.ssn;
            successSyncCount++;
          };

          request.onerror = event => {
            reject('Error al sincronizar las Empresas');
          }
        });

        //////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////

        transaction = this.database.transaction('filters', 'readwrite')

        let filtersStore = transaction.objectStore('filters')

        filtersStore.clear();

        this.filters.forEach((item) => {
          const request = filtersStore.add(item);

          request.onsuccess = (event) => {
            // event.target.result === customer.ssn;
            successSyncCount++;
          };

          request.onerror = event => {
            reject('Error al sincronizar los Filtros');
          }
        });

          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            }
          });

          Toast.fire({
            icon: "success",
            title: "Catálogos sincronizados."
          });
          
          resolve();
      })
    },

    syncCatalogs () {

      if ( this.isSyncingCatalogs) {
        return;
      }

      //axios.defaults.headers.common['Authorization'] = ('Bearer ' + apiToken);
      this.isSyncingCatalogs = true;

      axios.get('catalogs/sync')
            .then( (response) => {
                return response.data.data;
            })
            .then(  (data) => {
                console.log('=================');
                console.log('data catalogs ', data);
                console.log('=================');

                this.categories = data.categories;
                this.filters    = data.filters;
                this.companies  = data.companies;

                this.isSyncingCatalogs = false;
                
                this.storeCatalogs();

            })
            .catch( (error) => {
                console.log('error', error);

                this.isSyncingCatalogs = false;
            
                if (error.response) {
            
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    //console.log(error.response.headers);
                    console.log('error.response.status', error.response.status);
                    console.log('error.response.data', error.response.data);
            
                    switch(error.response.status) {
                    case 422:
                        let errors = error.response.data.errors;
                        /*this.setState({
                        errorPasswordOld: ((errors.current_password && errors.current_password.length) ? errors.current_password[0] : ''),
                        errorPasswordNew: ((errors.new_password && errors.new_password.length) ? errors.new_password[0] : ''),
                        errorPasswordConfirmation: ((errors.new_password_confirmation && errors.new_password_confirmation.length) ? errors.new_password_confirmation[0] : ''),
                        });*/
                        break;
                    default:
                        alert('Ocurrió un error');
                        break;
                    }
            
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error Message', error.message);

                    alert('Ocurrió un error');
                }
                //console.log(error.config);
          })
         

    },

    async saveBranch () {

      return new Promise((resolve, reject) => {
        let transaction  = this.database.transaction('branches', 'readwrite');

        transaction.oncomplete = (event) => {
          console.log('transaction complete', event)
          resolve(event.target.result)
        };

        transaction.onerror = (event) => {
          console.log('transaction error', event)
          reject('Error opening the database')
        };

        let now = new Date().getTime()

        this.branch.id = now

        if (this.branch.add_company && this.branch.companyName.trim().length == 0 ) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'El campo Sucursal es requerido.',
          })

          return;
        }

        if ( ! this.branch.add_company && this.branch.company == null ) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'El campo Sucursal es requerido.',
          })

          return;
        }

        if ( this.branch.add_company) {
          this.branch.company = null;
        } else {
          /*this.branch.companyName = this.companies.find((company) => {
            return company.id == this.branch.company
          }).name*/
          this.branch.companyName = '';

          this.branch.companyLabel = this.companies.find((company) => {
            return company.id == this.branch.company
          }).name
        }

        this.filters.forEach((filter) => {
          filter.values.forEach((value) => {
            if (value.selected) {
              this.branch.filters_ids.push(value.id)
            }
          })
        })

        this.filters.forEach((filter) => {
          filter.values.forEach((value) => {
            value.selected = false
          })
        })

        let request = transaction.objectStore('branches').add(this.branch)

        request.onsuccess = (event) => {
          console.log('success saving the branch', event)

          this.branches.push(this.branch);

          this.closeModal();

          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            }
          });

          Toast.fire({
            icon: "success",
            title: "Registrado correctamente."
          });

          resolve(event.target.result)
        };

        request.onerror = (event) => {
          console.log('request error', event)
          reject('Error saving the branch')
        };
      })

    }
  }
}
</script>
