<template>
    <div class="container">

      <div class="my-4">
        <img width="100px" class="rounded" alt="Vago logo" src="../assets/logo.png">
      </div>

      <h1 class="mb-4 text-muted">Bienvenid@</h1>
      
      <div class="row">
        <div class="col-12 col-md-4 mx-auto">
          <form @submit.prevent="login">

            <div class="form-floating mb-3">
              <input v-model="email" type="email" name="email" class="form-control" id="floatingInput" placeholder="name@example.com">
              <label for="floatingInput">Usuario</label>
            </div>

            <div class="form-floating mb-3">
              <input v-model="password" type="password" name="password" class="form-control" id="floatingPassword" placeholder="Contraseña">
              <label for="floatingPassword">Contraseña</label>
            </div>

            <div class="d-grid gap-2">
              <button :disabled="isLoggingIn" type="submit" class="btn btn-block btn-dark">Ingresar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data () {
      return {
        email: '',
        password: '',
        isLoggingIn: false
      }
    },
    props: {
      msg: String
    },

    methods: {
    login () {

      this.isLoggingIn = true

      this.$store
        .dispatch('login', {
          email: this.email,
          password: this.password,
          device_name: 'browser'
        })
        .then(() => {
          this.$router.push({ name: 'home' })
        })
        .catch(err => {
          console.log(err)
          alert('Error al iniciar sesión.');
        })
        .then(() => {
          this.isLoggingIn = false
        })
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
  
  </style>
  