<template>
    <div>
        <VuePhoneNumberInput 
            @update="phoneNumberUpdated" 
            :default-country-code="defaultCountryData" 
            :preferred-countries="countriesList" 
            :required="fieldRequired"
            v-model="phoneField" 
            :translations="phoneTranslations" 
        />

        <input hidden :name="`${fieldName}[country_code]`" :value="phone.country_code" />
        <input hidden :name="`${fieldName}[prefix]`" :value="phone.prefix" />
        <input hidden :name="`${fieldName}[phone]`" :value="phone.phone" />
        <input hidden :name="`${fieldName}[formatted]`" :value="phone.formatted" />

        <slot name="errors"></slot>
    </div>
</template>


<script>
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';

    export default {
        components: {
            VuePhoneNumberInput,
        },
        props: {
            fieldName: {
                required: true,
                type: String,
            },
            fieldRequired: {
                required: false,
                type: Boolean,
                default: false,
            },
            defaultCountry: {
                required: true,
                type: String,
                default: 'MX',
            },
            fieldValue: {
                required: false,
                type: Object,
                default: null,
            },
        },
        data: function () {
            return {
                phoneField: null,
                countriesList: ['MX', 'US', 'CA'],
                phoneTranslations: {
                    countrySelectorLabel: 'Código País',
                    countrySelectorError: 'Elige un País',
                    phoneNumberLabel: 'Número',
                    example: 'Ej.:'
                },
                phone: {
                    country_code: null,
                    phone: null,
                    prefix: null,
                    formatted: null,
                },
            }
        },
        created () {
            this.defaultCountryData = this.defaultCountry;
        },
        mounted() {

            if ( this.fieldValue ) {
                this.setValue(this.fieldValue);
            }
        },
        watch: {
            
        },
        methods: {
            phoneNumberUpdated (data) {
                console.log('phone number data', data);
                this.phone.country_code = data.countryCode;
                this.phone.phone              = data.nationalNumber;
                this.phone.prefix       = data.countryCallingCode;
                this.phone.formatted    = data.formattedNumber;

                this.$emit('phone-updated', this.phone);
            },

            setValue(phone) {
                this.phoneField = phone.formatted;
                this.phone.country_code = phone.country_code;
                this.phone.phone              = phone.phone;
                this.phone.prefix       = phone.prefix;
                this.phone.formatted    = phone.formatted;

                this.defaultCountryData = this.fieldValue.country_code;
            }
        }
    }
</script>